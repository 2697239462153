import { SidebarDropdownMenu, SidebarItem } from '@vtblife/layout-config/types';
import { matchPath, useLocation } from 'react-router-dom';

const getMatchPathProps = (item: SidebarItem) => {
    return { path: item.activeRoute || item.path || item.url || '', exact: item.exact };
};

export const useSidebarActiveItemsProps = (item: SidebarItem) => {
    const { pathname } = useLocation();
    let dropdownMenu: SidebarDropdownMenu[] | undefined;
    if (item.dropdownMenu) {
        dropdownMenu = item.dropdownMenu.map((subItem) => ({
            ...subItem,
            active: Boolean(matchPath(pathname, getMatchPathProps(subItem))),
        }));
    }
    const isActive = dropdownMenu
        ? dropdownMenu.some((item) => item.active)
        : Boolean(matchPath(pathname, getMatchPathProps(item)));

    return { isActive, dropdownMenu };
};
