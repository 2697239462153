(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("EventBus"), require("React"), require("ReactDOM"), require("Sentry"), require("VtblifeAxios"), require("mobx"), require("mobxReact"), require("workbox"));
	else if(typeof define === 'function' && define.amd)
		define("__LOADED_BUNDLES__", ["EventBus", "React", "ReactDOM", "Sentry", "VtblifeAxios", "mobx", "mobxReact", "workbox"], factory);
	else if(typeof exports === 'object')
		exports["__LOADED_BUNDLES__"] = factory(require("EventBus"), require("React"), require("ReactDOM"), require("Sentry"), require("VtblifeAxios"), require("mobx"), require("mobxReact"), require("workbox"));
	else
		root["__LOADED_BUNDLES__"] = root["__LOADED_BUNDLES__"] || {}, root["__LOADED_BUNDLES__"]["root"] = factory(root["EventBus"], root["React"], root["ReactDOM"], root["Sentry"], root["VtblifeAxios"], root["mobx"], root["mobxReact"], root["workbox"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__3c8f95455a25b67672af__, __WEBPACK_EXTERNAL_MODULE__86b0b08e488a8b44e09c__, __WEBPACK_EXTERNAL_MODULE_bc82efaef7dde9dae701__, __WEBPACK_EXTERNAL_MODULE__72e26f01e560daa602cd__, __WEBPACK_EXTERNAL_MODULE__3981f687bc41f489d3fa__, __WEBPACK_EXTERNAL_MODULE__687d2d32478f896129bd__, __WEBPACK_EXTERNAL_MODULE__02a7addb77a49218efda__, __WEBPACK_EXTERNAL_MODULE__4416ae11a3e08cd76e3b__) => {
return 