import React, { useEffect } from 'react';
import { ShellConfigContextValue, ShellUserRegionContextValue } from '@vtblife/layout-components/shell/contexts';
import { useUserRegion } from '@vtblife/layout-components/shell/hooks/use-user-region';
import { RegionModals } from '@vtblife/layout-components/shell/components/region-modals/region-modals';
import { inject, observer } from 'mobx-react';

import { Footer } from '../../components/footer/footer';
import { RootStore } from '../../stores/root';

const FooterIslandInner = ({ rootStore }: { rootStore: RootStore }) => {
    const config = rootStore.configStore.toJS();
    const shouldDisableRegionModal = rootStore.layoutStore.shouldDisableRegionModals;
    const userRegion = useUserRegion();

    useEffect(() => {
        const unsubscribe = userRegion.callSubscribe();
        return () => unsubscribe();
    }, [userRegion.callSubscribe]);

    return (
        <ShellConfigContextValue.Provider
            value={{
                config,
            }}
        >
            <ShellUserRegionContextValue.Provider
                value={{
                    userRegion,
                }}
            >
                <Footer />
                {!shouldDisableRegionModal && <RegionModals />}
            </ShellUserRegionContextValue.Provider>
        </ShellConfigContextValue.Provider>
    );
};

export const FooterIsland = inject('rootStore')(observer(FooterIslandInner)) as React.FC;
