import React, { ComponentProps, FC } from 'react';
import { Spinner } from '@vtblife/uikit/legacy';

import styles from './m2-spinner.module.css';

export const M2Spinner: FC<ComponentProps<typeof Spinner>> = (props) => (
    <div className={styles.loaderContainer}>
        <Spinner {...props} />
    </div>
);
