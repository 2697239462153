import React, { useEffect } from 'react';
import { RegionModals } from '@vtblife/layout-components/shell/components/region-modals/region-modals';
import {
    ShellConfigContextValue,
    ShellAuthorizeContextValue,
    ShellUserRegionContextValue,
} from '@vtblife/layout-components/shell/contexts';
import { useUserRegion } from '@vtblife/layout-components/shell/hooks/use-user-region';
import { inject, observer } from 'mobx-react';

import { Header } from '../../components/header/header';
import { RootStore } from '../../stores/root';

const HeaderIslandInner = ({ rootStore }: { rootStore: RootStore }) => {
    const logoVariant = rootStore.headerStore.header.logoVariant;
    const pathname = rootStore.historyStore.location.pathname;
    const user = rootStore.userStore.user;
    const isAlreadyPassedM2ProVerification = rootStore.userStore.isAlreadyPassedM2ProVerification;
    const config = rootStore.configStore.toJS();
    const shouldDisableRegionModal = rootStore.layoutStore.shouldDisableRegionModals;
    const userRegion = useUserRegion();
    const isAuthorized = Boolean(user && user.isAuthenticated);

    useEffect(() => {
        const unsubscribe = userRegion.callSubscribe();
        return () => unsubscribe();
    }, [userRegion.callSubscribe]);

    return (
        <ShellConfigContextValue.Provider
            value={{
                config,
            }}
        >
            <ShellAuthorizeContextValue.Provider
                value={{
                    authorize: {
                        user,
                        isAuthorized,
                        verification: { isAlreadyPassedM2ProVerification },
                    },
                }}
            >
                <ShellUserRegionContextValue.Provider
                    value={{
                        userRegion,
                    }}
                >
                    <Header pathname={pathname} logoVariant={logoVariant} />
                    {!shouldDisableRegionModal && <RegionModals />}
                </ShellUserRegionContextValue.Provider>
            </ShellAuthorizeContextValue.Provider>
        </ShellConfigContextValue.Provider>
    );
};

export const HeaderIsland = inject('rootStore')(observer(HeaderIslandInner)) as React.FC;
