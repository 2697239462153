import React, { useState } from 'react';
import cn from 'classnames';
import axios, { AxiosRequestConfig } from '@vtblife/axios';
import { NotificationPayload, NotificationEvent, ShowCaptcha } from '@vtblife/event-bus-events';
import { EventBus } from '@vtblife/event-bus';

import styles from './notifications-tester.module.css';

const ERROR_ORIGIN_MESSAGE = 'this is a test error generated at notifications-tester component';

const CLIENT = axios.create();
CLIENT.defaults.baseURL = '/api/auth';
const REQUEST_METHODS = ['get', 'post', 'delete', 'put', 'patch'] as AxiosRequestConfig['method'][];

export const publishErrorNotification = (
    message: NotificationPayload['message'],
    options?: NotificationPayload['options'],
    reportableErrorData?: NotificationPayload['reportableErrorData'],
) => {
    const notification: NotificationEvent = {
        type: 'app:notification',
        data: {
            type: 'error',
            message,
            options,
            reportableErrorData: {
                error: options?.error,
                extra: { 'error origin': ERROR_ORIGIN_MESSAGE },
                ...reportableErrorData,
            },
        },
        category: 'simple',
    };
    console.log('--- publishErrorNotification', { notification });
    EventBus.getInstance().publish(notification);
};

interface SimulationConfig {
    status?: number;
    method?: AxiosRequestConfig['method'];
    headers?: AxiosRequestConfig['headers'];
}

const simulateUnhandledAxiosError = async (params: SimulationConfig) => {
    await CLIENT({
        method: params.method,
        url: `/v2/management/user`,
    }).catch((error) => {
        if (params.status) {
            error.response.status = params.status;
        }
        if (params.headers) {
            error.response.headers = { ...error.response.headers, ...params.headers };
        }
        error.message = ERROR_ORIGIN_MESSAGE;
        console.log('--- simulateUnhandledError', { error });
        throw error;
    });
};

const simulateHandledAxiosError = async (params: SimulationConfig, payload?: NotificationPayload) => {
    await CLIENT({
        method: params.method,
        url: `/v2/management/user`,
    }).catch((error) => {
        publishErrorNotification(payload?.message, { ...payload?.options, error });
    });
};

const simulateHandledXhrError = ({ method }: SimulationConfig) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method?.toUpperCase() as string, 'api/some-unexpected-url');

    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status !== 200) {
                const error = new Error(xhr.response || xhr.statusText);
                console.log('--- simulateXhrError', { error });
                publishErrorNotification('some custom message', { error });
            }
        }
    };
    xhr.send();
};

const simulateHandledFetchError = async (
    { method }: SimulationConfig,
    payload?: NotificationPayload,
    sentryEventData?: Omit<NotificationPayload['reportableErrorData'], 'error'>,
) => {
    try {
        await fetch('api/some-unexpected-url', { method }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            return res.text().then((text) => {
                throw new Error(text);
            });
        });
    } catch (error) {
        publishErrorNotification(payload?.message, { ...payload?.options, error }, { error, ...sentryEventData });
    }
};

const simulateUnhandledFetchError = async ({ method }: SimulationConfig) => {
    await fetch('api/some-unexpected-url', { method }).then((res) => {
        if (!res.ok) {
            return res.text().then((text) => {
                text = JSON.stringify({ ...JSON.parse(text), 'error origin': ERROR_ORIGIN_MESSAGE });
                const error = new Error(text);
                console.log('--- simulateUnhandledFetchError', { error });
                throw error;
            });
        }
        return;
    });
};

const simulateUnhandledFetchErrorBlockedByQrator = async () => {
    await axios('/api/root-bff/dev/403');
};

const RequestButton: React.FC<{ onClick: VoidFunction }> = ({ onClick, children }) => {
    return (
        <button style={{ marginRight: '16px' }} onClick={onClick}>
            {children}
        </button>
    );
};

export const NotificationsTester: React.FC = () => {
    const [isVisible, setVisible] = useState(true);

    return (
        <div className={cn(styles.container, isVisible && styles.expanded)}>
            <button onClick={() => setVisible((isVisible) => !isVisible)}>
                {isVisible ? 'Hide' : 'Show notifications demo'}
            </button>
            {isVisible && (
                <div>
                    <h2>Notifications UI demo</h2>
                    <p>Смотри в консоли генерируемый event</p>
                    <a href="https://conf.m2.ru/pages/viewpage.action?pageId=102409202">
                        Перейти к описанию в Confluence
                    </a>
                    <br />
                    <br />
                    <article>
                        <h3>Неперехваченные исключения</h3>
                        <h4>Axios: Если делаем вывод, что сервер лежит - предупреждаем пользователя</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => simulateUnhandledAxiosError({ status: 500, method })}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>

                        <h4>
                            Axios: Если ошибка на стороне Variti - просим отправить скриншот страницы в поддержку, чтобы
                            пользователь оперативно получил помощь
                        </h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() =>
                                        simulateUnhandledAxiosError({
                                            status: 502,
                                            method,
                                            headers: { 'x-variti-ccr': 'Variti stupid bug' },
                                        })
                                    }
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: В остальных случаях</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton key={method} onClick={() => simulateUnhandledAxiosError({ method })}>
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Fetch</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton key={method} onClick={() => simulateUnhandledFetchError({ method })}>
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: 403 Доступ запрещен</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => simulateUnhandledAxiosError({ method, status: 403 })}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: 503 Сервис недоступен, x-trace-id пустой и есть cf-ray</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() =>
                                        simulateUnhandledAxiosError({
                                            method,
                                            status: 503,
                                            headers: {
                                                'x-trace-id': '',
                                                'cf-ray': '230b030023ae2822-SJC',
                                            },
                                        })
                                    }
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: 520 Неизвестная ошибка, x-trace-id пустой и есть cf-ray</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() =>
                                        simulateUnhandledAxiosError({
                                            method,
                                            status: 520,
                                            headers: {
                                                'x-trace-id': '',
                                                'cf-ray': '230b030023ae2822-SJC',
                                            },
                                        })
                                    }
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>unhandled error</h4>
                        <p>
                            <RequestButton
                                onClick={() => {
                                    throw new Error('this is a test error generated at notifications-tester component');
                                }}
                            >
                                Симулировать Error
                            </RequestButton>
                        </p>
                        <h4>QRATOR</h4>
                        <p>
                            <RequestButton
                                onClick={() => {
                                    simulateUnhandledFetchErrorBlockedByQrator();
                                }}
                            >
                                Симулировать блокировку QRATOR
                            </RequestButton>
                        </p>
                    </article>
                    <br />

                    <article>
                        <h3>Перехваченные исключения</h3>
                        <h4>Axios: без кастомных параметров</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton key={method} onClick={() => simulateHandledAxiosError({ method })}>
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: с кастомными параметрами</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() =>
                                        simulateHandledAxiosError(
                                            { method },
                                            {
                                                type: 'error',
                                                message: 'Custom error message',
                                                options: {
                                                    title: 'Custom error title',
                                                    additionalMessage: 'Some additional information for user',
                                                },
                                            },
                                        )
                                    }
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Axios: 403 Доступ запрещен</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => simulateUnhandledAxiosError({ method, status: 403 })}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>onerror XHR</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => {
                                        simulateHandledXhrError({ method });
                                    }}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Fetch: без кастомных параметров</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => {
                                        simulateHandledFetchError({ method });
                                    }}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                        <h4>Fetch: с кастомными параметрами</h4>
                        <p>
                            {REQUEST_METHODS.map((method) => (
                                <RequestButton
                                    key={method}
                                    onClick={() => {
                                        simulateHandledFetchError(
                                            { method },
                                            {
                                                type: 'error',
                                                message: 'Custom error message',
                                                options: {
                                                    title: 'Custom error title',
                                                    additionalMessage: 'Some additional information for user',
                                                },
                                            },
                                            {
                                                tags: {
                                                    reason: 'TEST_REASON',
                                                },
                                            },
                                        );
                                    }}
                                >
                                    {method}
                                </RequestButton>
                            ))}
                        </p>
                    </article>
                    <article>
                        <h3>Тестирование капчи</h3>
                        <h4>Показать Yandex Captcha</h4>
                        <p>
                            <RequestButton
                                key="yandex-captcha"
                                onClick={() => {
                                    const event: ShowCaptcha = {
                                        type: 'captcha:show',
                                        category: 'simple',
                                        data: {
                                            test: true,
                                        },
                                    };
                                    EventBus.getInstance().publish(event);
                                }}
                            >
                                Показать капчу
                            </RequestButton>
                        </p>
                    </article>
                </div>
            )}
        </div>
    );
};
