import { Button, Typography } from '@vtblife/uikit';
import React from 'react';

import styles from './invoice.module.css';
import InvoiceCompletedIcon from './icons/invoice-completed.component.svg';

export const InvoiceCompleted = () => {
    return (
        <div className={styles.paymentInfo}>
            <div className={styles.invoiceCompletedIcon}>
                <InvoiceCompletedIcon />
            </div>
            <Typography variant="h1" responsive={true} className={styles.paymentCompleted}>
                Счёт оплачен
            </Typography>
            <Button
                variant="secondary"
                dataTest="to-main-page-button"
                block={true}
                onClick={() => {
                    location.href = '/';
                }}
            >
                На главную
            </Button>
        </div>
    );
};
