import React, { FC } from 'react';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';
import type { SidebarItem, SidebarLink } from '@vtblife/layout-config/types';
import { Tooltip, useTooltip } from '@vtblife/uikit';

import styles from './button.module.css';
import { MenuLinkInterface } from '../../types/menu-link.interface';
import { useSidebarActiveItemsProps } from '../../hooks/use-sidebar-active-items-props';
import { useSidebarMenuState } from '../../hooks/use-sidebar-menu-state';
import { isTouchDevice } from '../../helpers';

const DATA_TEST_PREFIX = 'side-link-';

export type SidebarButtonProps = Omit<SidebarItem, 'dropdownMenu'> & {
    onClick: (event: React.MouseEvent, item: MenuLinkInterface) => void;
    active?: boolean;
    closed?: boolean;
    initialCollapsed?: boolean;
    className?: string;
    freshCount?: number;
    dropdownMenu?: Array<
        SidebarLink & {
            freshCount?: number;
        }
    >;
};

export const Button: FC<SidebarButtonProps> = (item) => {
    const { isActive, dropdownMenu } = useSidebarActiveItemsProps(item);
    const hasDropdownMenu = Boolean(dropdownMenu && dropdownMenu.length);

    return hasDropdownMenu ? (
        <CollapseLinkButtons {...item} dropdownMenu={dropdownMenu} active={isActive} />
    ) : (
        <LinkButton {...item} active={isActive} />
    );
};

export const LinkButton: FC<Omit<SidebarButtonProps, 'dropdownMenu'>> = ({
    icon,
    active,
    children,
    onClick,
    url,
    path,
    closed,
    className,
    target,
    id,
}) => {
    const { to, registerControl } = useTooltip();
    return (
        <>
            <a
                target={target}
                rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                href={url || path}
                data-test={`${DATA_TEST_PREFIX}${path || url}`}
                onClick={(e) => onClick(e, { url, path, target, id, isSidebarEl: true })}
                className={cn(className, styles.button, closed && styles.closed, active && styles.active)}
            >
                <span ref={registerControl} className={styles.icon}>
                    <Icon name={icon} />
                </span>
                <span className={styles.content}>{children}</span>
            </a>

            {closed && !isTouchDevice() && (
                <Tooltip placement="body" direction="right" to={to}>
                    {children}
                </Tooltip>
            )}
        </>
    );
};

export const CollapseLinkButtons: FC<SidebarButtonProps> = ({
    id,
    closed,
    active,
    onClick,
    icon,
    dropdownMenu = [],
    children,
}) => {
    const [open, toggleOpen] = useSidebarMenuState(id, active);
    const { to, registerControl } = useTooltip();

    return (
        <>
            <span
                onClick={toggleOpen}
                data-test={`${DATA_TEST_PREFIX}${id}`}
                className={cn(styles.button, closed && styles.closed, active && styles.active)}
            >
                <span ref={registerControl} className={styles.icon}>
                    <Icon name={icon} />
                </span>
                <span className={styles.content}>{children}</span>
                <span className={styles.toggler}>
                    <Icon name={open ? 'chevron-up' : 'chevron-down'} size="s" />
                </span>
            </span>
            <div className={cn(styles.collapseWrapper, (!open || closed) && styles.collapsed)}>
                {dropdownMenu?.map(({ url, path, target, displayName, active, id, freshCount }) => {
                    return (
                        <a
                            target={target}
                            key={id}
                            data-test={`${DATA_TEST_PREFIX}${path || url}`}
                            href={url || path}
                            onClick={(e) => onClick(e, { path, url, target, id, isSidebarEl: true })}
                            className={cn(styles.button, styles.collapse, active && styles.active)}
                        >
                            <span className={styles.text}>{displayName}</span>
                            {freshCount ? (
                                <span className={styles.fresh}>{freshCount >= 99 ? '99+' : `+${freshCount}`}</span>
                            ) : null}
                        </a>
                    );
                })}
            </div>
            {closed && !isTouchDevice() && (
                <Tooltip placement="body" direction="right" to={to}>
                    {children}
                </Tooltip>
            )}
        </>
    );
};
