import { History, LocationDescriptorObject } from 'history';
import { SynchronizedHistory } from 'mobx-react-router';

import { isGatsbyRoute } from '../utils';

export const patchHistoryGatsbyRedirects = (history: History, gatsbyRoutes: HtmlManifest) => {
    const onlyForGatsbyRoutes = (method: History['push'], routes: string[]): History['push'] => {
        return function (
            this: SynchronizedHistory,
            pathOrLocationDescriptor: string | LocationDescriptorObject,
            ...rest: any[]
        ) {
            const path =
                typeof pathOrLocationDescriptor === 'string'
                    ? pathOrLocationDescriptor
                    : pathOrLocationDescriptor.pathname || '';

            if (isGatsbyRoute(routes, path)) {
                window.location.href = path;
            } else {
                method.call(this, pathOrLocationDescriptor, ...rest);
            }
        };
    };
    history.push = onlyForGatsbyRoutes(
        history.push,
        gatsbyRoutes.map(({ route }) => route),
    );
    history.replace = onlyForGatsbyRoutes(
        history.replace,
        gatsbyRoutes.map(({ route }) => route),
    );
};
