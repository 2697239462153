import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '@vtblife/axios';
import { Size } from '@vtblife/uikit/legacy';

import { SsrContext } from './ssr-context';
import { bundleProvider } from './bundle-loader';
import { ConfigStore } from '../stores/config';
import { M2Spinner } from '../components/m2-spinner/m2-spinner';
import { scrollToTop } from '../utils';

interface LandingPageProps {
    configStore: ConfigStore;
}

const SSRPageBase: React.VFC<LandingPageProps> = ({ configStore }) => {
    const { loading, history, remoteBundle, content } = useRemotePage(configStore);

    if (loading || !remoteBundle) {
        return <M2Spinner size={Size.Large} />;
    }

    return React.createElement(remoteBundle.Page, {
        content,
        history,
    });
};

export const SSRPage = inject('configStore')(SSRPageBase) as unknown as React.VFC<Record<string, unknown>>;

function useRemotePage(configStore: ConfigStore) {
    const location = useLocation();
    const history = useHistory();
    const context = useSSRContext();
    const [loading, setLoading] = useState(false);
    const [ssrEntry, setSsrEntry] = useState(configStore.ssrManifest!.cache[location.pathname]);
    const remoteBundle = context.blocks[ssrEntry?.bundleName];

    useEffect(() => {
        if (!remoteBundle) {
            (async () => {
                setLoading(true);
                try {
                    const { data } = await axios.get<any>('/api/root-bff/api/props', {
                        params: {
                            path: location.pathname,
                        },
                    });

                    context.setBlocks(data.bundleName, await bundleProvider.getBundle(data));
                    setSsrEntry(data);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [ssrEntry, remoteBundle]);

    useEffect(() => {
        scrollToTop();
    }, [location.pathname]);

    return {
        history,
        loading,
        remoteBundle,
        content: ssrEntry?.content,
    };
}

function useSSRContext() {
    const context = React.useContext(SsrContext);
    if (context === undefined) {
        throw new Error('useSSRContext must be used within a SSRContext.Provider');
    }

    return context;
}
