import React, { FC } from 'react';
import { MicroBundle } from '@vtblife/micro-frontend-core';
import { Route } from '@vtblife/layout-config/types';
import { SynchronizedHistory } from 'mobx-react-router';
import { inject } from 'mobx-react';

import { ErrorBoundary } from '../error-boundary/error-boundary';
import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { BundleTreeStore } from '../../stores/bundle-tree';
import { useGrowthBookFeaturesByPath } from './use-growth-book';

interface InjectedProps {
    configStore: ConfigStore;
    userStore: UserStore;
    bundleTreeStore: BundleTreeStore;
}

interface Props {
    route: Route;
    initialState: InitialState;
    history: SynchronizedHistory;
}

function MicroApplicationComponent({
    userStore,
    configStore,
    bundleTreeStore,
    route,
    initialState,
    history,
}: Props & InjectedProps) {
    useGrowthBookFeaturesByPath(route);

    return (
        // @ts-expect-error
        <ErrorBoundary
            extraInfo={{
                user: userStore.toJS(),
                config: configStore.toJS(),
                bundleTreeRoutes: bundleTreeStore.bundleTreeRoutes,
            }}
        >
            <MicroBundle
                name={route.applicationName}
                path={route.assetsUrl || configStore.getApplicationPath(route.applicationName)}
                basePath={route.path}
                history={history}
                authInfo={userStore.toJS()}
                config={configStore.toJS()}
                initialState={initialState}
            />
        </ErrorBoundary>
    );
}

export const MicroApplication: FC<Props> = inject(
    'configStore',
    'userStore',
    'bundleTreeStore',
)(MicroApplicationComponent);
