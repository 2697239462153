import { Button, Link, Typography } from '@vtblife/uikit';
import React, { useState } from 'react';

import { InvoiceDto } from '@vtblife/billing-rest-api';

import cn from 'classnames';

import { PaymentMethod } from '@vtblife/billing-rest-api/axios-gen/api';

import { useHistory } from 'react-router-dom';

import styles from './invoice.module.css';
import { SUPPORTED_PAYMENT_METHODS } from './constants';
import { getFormattedAmount } from './amount';

import AcquiringLogo from './icons/acquiring.component.svg';
import VtbLogo from './icons/vtb.component.svg';

type PaymentMethodType = (typeof SUPPORTED_PAYMENT_METHODS)[number];

interface InvoiceMethod {
    title: string;
    description: string;
    selected: boolean;
    type: PaymentMethodType;
    onClick(type: PaymentMethodType): void;
}

const PAYMENT_METHODS_CONFIG: Record<
    PaymentMethodType,
    {
        buttonText: string;
        logo: React.ReactNode;
        onPaymentMethodSelect: (paymentMethod: PaymentMethod, invoiceId: string) => void;
    }
> = {
    ACQUIRING: {
        buttonText: 'Оплатить картой',
        logo: <AcquiringLogo />,
        onPaymentMethodSelect: async (_, invoiceId) => {
            location.href = `/api/public/v1/invoices/${invoiceId}/acquiring`;
        },
    },
    VTB_ONLINE_SECTION: {
        buttonText: 'Оплатить через ВТБ Онлайн',
        logo: <VtbLogo />,
        onPaymentMethodSelect: (paymentMethod: PaymentMethod) => {
            location.href = paymentMethod.vtbDeepLink!;
        },
    },
};

const InvoiceMethod = ({ title, description, selected, onClick, type }: InvoiceMethod) => {
    return (
        <div
            className={cn(styles.invoiceMethod, { [styles.selected]: selected })}
            onClick={() => {
                onClick(type);
            }}
        >
            <span>
                <Typography variant="h4" responsive={true}>
                    {title}
                </Typography>
                <Typography variant="primary" color="secondary" responsive={true}>
                    {description}
                </Typography>
            </span>
            {PAYMENT_METHODS_CONFIG[type].logo}
        </div>
    );
};

export const InvoicePayment = ({ invoiceData }: { invoiceData: InvoiceDto | null }) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType>('ACQUIRING');

    const history = useHistory();

    const paymentMethods = (invoiceData?.paymentMethods || []).filter((method) =>
        SUPPORTED_PAYMENT_METHODS.includes(method.type as PaymentMethodType),
    );

    if (!invoiceData || paymentMethods.length === 0) {
        history.push('/services/payment-result/error');
        return null;
    }

    const handlePaymentMethodClick = (method: PaymentMethodType) => {
        setSelectedPaymentMethod(method);
    };

    return (
        <div className={styles.paymentInfo}>
            <div className={styles.title}>
                <Typography variant="h3" responsive={true}>
                    Итого к оплате
                </Typography>
                <Typography variant="h3" responsive={true}>
                    {getFormattedAmount(invoiceData.amount || '0')}
                </Typography>
            </div>
            <hr className={styles.separator} />
            <div className={styles.description}>
                {(invoiceData.operationNames || []).map((operation) => (
                    <Typography key={operation} variant="primary" color="secondary" responsive={true}>
                        {operation}
                    </Typography>
                ))}
            </div>
            <Typography variant="h2" responsive={true} className={styles.paymentText}>
                Выберите способ оплаты
            </Typography>
            {paymentMethods.map((method) => {
                switch (method.type) {
                    case 'ACQUIRING':
                        return (
                            <InvoiceMethod
                                key={method.type}
                                type={method.type}
                                selected={selectedPaymentMethod === 'ACQUIRING'}
                                title="Банковской картой"
                                description="VISA, Mastercard, MIR"
                                onClick={handlePaymentMethodClick}
                            />
                        );
                    case 'VTB_ONLINE_SECTION':
                        return (
                            <InvoiceMethod
                                key={method.type}
                                type={method.type}
                                selected={selectedPaymentMethod === 'VTB_ONLINE_SECTION'}
                                title="ВТБ Онлайн"
                                description="Оплата через интернет-банк"
                                onClick={handlePaymentMethodClick}
                            />
                        );

                    default:
                        return null;
                }
            })}
            <div className={styles.payButton}>
                <Button
                    variant="primary"
                    dataTest="pay-button"
                    block={true}
                    onClick={() => {
                        PAYMENT_METHODS_CONFIG[selectedPaymentMethod].onPaymentMethodSelect(
                            paymentMethods.find((method) => method.type === selectedPaymentMethod)!,
                            invoiceData.id!,
                        );
                    }}
                >
                    {PAYMENT_METHODS_CONFIG[selectedPaymentMethod].buttonText}
                </Button>
            </div>
            <Typography variant="secondary-alone" responsive={true} color="secondary" className={styles.verifiedText}>
                Verified by Visa, SecureCode, MIR Accept
            </Typography>
            <div className={styles.welcomeText}>
                <p>
                    <strong>Мы всегда рады вам!</strong> Если возникли вопросы, <br /> позвоните{' '}
                    <Link href="tel:+74951362818">+7 495 136‑28‑18</Link> или напишите{' '}
                    <Link href="mailto:cc@m2.ru">cc@m2.ru</Link>
                </p>
            </div>
        </div>
    );
};
