import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { RouterStore, SynchronizedHistory } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';

import { Shell } from './shell';
import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { BundleTreeStore } from '../../stores/bundle-tree';
import { HeaderStore } from '../../stores/header';
import { ModalStore } from '../../stores/modal-store';
import { AuthModalTester } from '../../components/auth-modal-tester/auth-modal-tester';
import { NotificationsTester } from '../../components/notifications-tester/notifications-tester';

export interface AppProps {
    initialState: InitialState;
    history: SynchronizedHistory;
    configStore: ConfigStore;
    headerStore: HeaderStore;
    userStore: UserStore;
    bundleTreeStore: BundleTreeStore;
    historyStore: RouterStore;
    pathname: string;
    cookie: string;
    modalStore: ModalStore;
}

const ModalsManagerInner = (props: AppProps) => {
    const { configStore, headerStore, history, cookie } = props;

    useEffect(() => {
        (async () => {
            if (!configStore.isInitialized) {
                await configStore.fetchConfig();
            }
        })();
    }, [configStore.isInitialized]);

    if (!configStore.isInitialized) {
        return null;
    }

    const isNotificationsDemoAvailable = configStore.isFeatureAvailable('root-notifications-tester');

    return (
        <Router history={history}>
            {cookie.includes('AuthModalTester') && <AuthModalTester />}
            {cookie.includes('root-notifications-tester') && isNotificationsDemoAvailable && <NotificationsTester />}
            {/*
                // @ts-expect-error */}
            <Shell {...props} logoVariant={headerStore.header.logoVariant} />
        </Router>
    );
};

export const ModalsManagerIsland = inject(
    'configStore',
    'userStore',
    'bundleTreeStore',
    'historyStore',
    'headerStore',
    'modalStore',
)(observer(ModalsManagerInner)) as React.FC<
    Omit<AppProps, 'configStore' | 'userStore' | 'bundleTreeStore' | 'historyStore' | 'headerStore' | 'modalStore'>
>;
