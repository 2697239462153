import React, { FC } from 'react';

import { Logo } from '@vtblife/layout-config/types';

import styles from '../sidebar.module.css';
import { logoImages } from './images';
import { LogoVariant } from '../../header/logo/images';

type SidebarLogoProps = {
    logo: Logo;
    isSidebarExpanded: boolean;
    onClick: (e: React.MouseEvent) => void;
};

export const SidebarLogo: FC<SidebarLogoProps> = ({ logo, onClick, isSidebarExpanded }) => {
    const { image: M2 } = logoImages['m2'];
    const variant: LogoVariant = logo.variant;
    if (!variant || !logoImages[variant] || variant === 'm2') {
        return (
            <a onClick={onClick}>
                <M2 />
            </a>
        );
    }
    const { image: PartnerLogo, position, collapsedImage: CollapsedLogo } = logoImages[variant];

    if (!isSidebarExpanded && CollapsedLogo) {
        return <CollapsedLogo />;
    }

    return (
        <>
            {position === 'left' && (
                <>
                    <span className={styles.partnerLogo}>
                        <PartnerLogo />
                    </span>
                    <span className={styles.divider} />
                </>
            )}
            {position === 'single' && (
                <a onClick={onClick}>
                    <PartnerLogo />
                </a>
            )}
            {position !== 'single' && (
                <a onClick={onClick}>
                    <M2 />
                </a>
            )}
            {position === 'right' && (
                <>
                    <span className={styles.divider} />
                    <span className={styles.partnerLogo}>
                        <PartnerLogo />
                    </span>
                </>
            )}
        </>
    );
};
