import { useEffect } from 'react';
import type { Route } from '@vtblife/layout-config/types';

import { growthBookApiService } from '../../services/growth-book-api-service';

function useGrowthBookFeaturesByPath(route: Route) {
    useEffect(() => {
        const fetchData = async () => {
            const path = route.path;
            const data = await growthBookApiService.getFeaturesByPath(path);
            console.debug('useGrowthBookRouteTracking', data);

            // Send Analytics
            data.trackingData.forEach(({ experiment, result }) =>
                window.dataLayer.push({
                    event: 'experiment_viewed',
                    experiment_id: experiment.key,
                    variation_id: result.key,
                    hash_attribute: result.hashAttribute,
                    hash_value: result.hashValue,
                }),
            );
        };

        // INFO: Hardcoded condition, so it doesn't affect other routes
        if ('/personal-area' === route.path) {
            fetchData();
        }
    }, [route.path]);
}

export { useGrowthBookFeaturesByPath };
