import React from 'react';

import { bundleProvider } from './bundle-loader';

export interface SsrContext {
    blocks: Record<string, any>;
    setBlocks: (bundleName: string, blocks: any) => void;
}

export async function createSsrContextValue(): Promise<SsrContext> {
    const initialState = window.__INITIAL_STATE__;
    const ssrEntry = initialState.configStore.ssrManifest.cache[location.pathname];

    const now = Date.now();
    const bundle = await bundleProvider.getBundle(ssrEntry);
    console.log(`fetch initial bundle for hydration: ${Date.now() - now}ms`);

    const blocks: Record<string, any> = {
        [ssrEntry.bundleName]: bundle,
    };
    const ssrContextValue = {
        blocks,
        setBlocks(bundleName: string, bundle: any) {
            blocks[bundleName] = bundle;
        },
    };

    return ssrContextValue;
}

export function createDefaultSsrContextValue(): SsrContext {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return { blocks: {}, setBlocks: () => {} };
}

export const SsrContext = React.createContext<SsrContext>(createDefaultSsrContextValue());
