import React, { useEffect } from 'react';
import { inject } from 'mobx-react';
import { parse } from 'query-string';
import { Size } from '@vtblife/uikit/legacy';

import { UserStore } from '../../stores/user';
import { BundleTreeStore } from '../../stores/bundle-tree';
import { ConfigStore } from '../../stores/config';
import { M2Spinner } from '../../components/m2-spinner/m2-spinner';

interface LogoutPageProps {
    userStore: UserStore;
    bundleTreeStore: BundleTreeStore;
    configStore: ConfigStore;
}

const LogoutPage = ({ userStore }: LogoutPageProps) => {
    useEffect(() => {
        (async function logout() {
            await userStore.logout();
            const queryParams = parse(document.location.search, { decode: true });
            const redirectPath = (queryParams.retpath as string) || '/';
            document.location.replace(redirectPath);
        })();
    }, []);

    return <M2Spinner size={Size.Large} />;
};

export const Logout = inject('userStore', 'bundleTreeStore', 'configStore')(LogoutPage);
