import React, { useMemo } from 'react';
import { SidebarItem } from '@vtblife/layout-config/types';

import { mapUpdatesToSidebar } from '../../utils';
import { Button, SidebarButtonProps } from '../button/button';
import { NewsContainer } from '../../news-container/news-container';
import { MenuLinkInterface } from '../../types/menu-link.interface';
import { FreshDot } from '../../fresh-dot/fresh-dot';
import { useUpdatesContext } from '../../shell/contexts';

const SidebarButtonComponent: React.FC<SidebarButtonProps> = (props) => {
    if (props.id === 'news') {
        return (
            <NewsContainer isSidebarClosed={props.closed}>
                <Button {...props} />
            </NewsContainer>
        );
    }

    if (props.freshCount !== undefined && props.freshCount > 0) {
        return (
            <FreshDot isCircleVisible isSidebarClosed={props.closed}>
                <Button {...props} />
            </FreshDot>
        );
    }

    return <Button {...props} />;
};

export const SidebarItemsList: React.FC<{
    list: SidebarItem[];
    onMenuItemClick: (event: React.MouseEvent, item: MenuLinkInterface) => void;
    open: boolean;
}> = ({ list, onMenuItemClick, open }) => {
    const { servicesUpdates } = useUpdatesContext();
    const itemsWithDots = useMemo(() => mapUpdatesToSidebar(list, servicesUpdates), [list, servicesUpdates]);
    return (
        <>
            {itemsWithDots.map((item) => (
                <SidebarButtonComponent key={item.id} onClick={onMenuItemClick} closed={!open} {...item}>
                    {item.displayName}
                </SidebarButtonComponent>
            ))}
        </>
    );
};
