export interface CookieConsentOptions {
    name: string;
    value: string;
    maxAgeInDays: number;
}

export const agreementCookieOptions = {
    baseName: 'agreement_accepted',
    getName: function (userId: string) {
        return `${this.baseName}_${userId}`;
    },
    value: 'true',
    maxAgeInDays: 30 * 12,
};

export const cookieConsentOptions: CookieConsentOptions = {
    name: 'cookies_accepted',
    value: 'true',
    maxAgeInDays: 30 * 6, // 30 * 6 = 180 days ~= 6 months
};
