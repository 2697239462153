import React, { FC, useRef } from 'react';
import cn from 'classnames';
import useOnClickOutside from '@vtblife/uikit/dist/hooks/use-on-click-outside';
import { Logo, Sidebar as SidebarType, SidebarItem } from '@vtblife/layout-config/types';
import { Typography } from '@vtblife/uikit';

import styles from './sidebar.module.css';
import { SidebarLogo } from './logo/sidebar-logo';
import { Toggler } from './toggler/toggler';
import { Button } from './button/button';
import { MenuLinkInterface } from '../types/menu-link.interface';
import { formatPhone, getProfileHeaderByRoleType, isAnonymousUser, isPhoneNumberString } from '../utils';
import { useAuthorizeContext } from '../shell';
import { WalletLink } from './wallet-link/wallet-link';
import { useDisplayWallet } from '../hooks/use-display-wallet';
import { useSidebarToggle } from './hooks';
import { SidebarItemsList } from './sidebar-items-list/sidebar-items-list';

export interface SidebarProps {
    className?: string;
    footerItems: SidebarItem[];
    handleOpen?: (isOpen: boolean) => void;
    items: SidebarItem[];
    groupedItems?: SidebarType['groupedItems'];
    logo: Logo;
    onMenuItemClick: (event: React.MouseEvent, item: MenuLinkInterface) => void;
}

export const Sidebar: FC<SidebarProps> = ({ className, onMenuItemClick, items, footerItems, logo, groupedItems }) => {
    const { open, setOpen, handleClickOutside } = useSidebarToggle();
    const { shouldDisplayWallet } = useDisplayWallet();
    const { user } = useAuthorizeContext();
    const { currentRoleType = '', username: rawUsername } = user || {};
    const sidebar = useRef(null);
    const username = rawUsername && isPhoneNumberString(rawUsername) ? formatPhone(rawUsername) : rawUsername;

    useOnClickOutside([sidebar], handleClickOutside);

    const toggleOpen = () => setOpen((open) => !open);

    const handleClick = () => {
        if (!open) {
            setOpen(true);
        }
    };

    return (
        <aside onClick={handleClick} className={cn(className, styles.wrapper, open && styles.open)}>
            <div ref={sidebar} className={cn(styles.sidebar, open ? styles.open : styles.closed)}>
                <div className={styles.logoSlot}>
                    <SidebarLogo
                        logo={logo}
                        isSidebarExpanded={open}
                        onClick={(e) => onMenuItemClick(e, { path: logo.href || '/' })}
                    />
                </div>
                {open && !isAnonymousUser(username) && (
                    <div className={styles.profile}>
                        {username && (
                            <Typography bold variant="small-alone" color="secondary">
                                <>
                                    {username} &middot; {getProfileHeaderByRoleType(currentRoleType)}
                                </>
                            </Typography>
                        )}
                        {shouldDisplayWallet && (
                            <div className={styles.wallet}>
                                <WalletLink />
                            </div>
                        )}
                    </div>
                )}
                <div className={styles.mainSection}>
                    <>
                        <SidebarItemsList list={items} open={open} onMenuItemClick={onMenuItemClick} />
                        {groupedItems?.map((groupedItem) => (
                            <React.Fragment key={groupedItem.name}>
                                {open && (
                                    <Typography className={styles.groupName} bold variant="secondary" color="secondary">
                                        {groupedItem.name}
                                    </Typography>
                                )}
                                <SidebarItemsList
                                    list={groupedItem.items}
                                    open={open}
                                    onMenuItemClick={onMenuItemClick}
                                />
                            </React.Fragment>
                        ))}
                    </>
                </div>
                <div className={styles.footer}>
                    <div className={styles.links}>
                        {footerItems.map((item) => (
                            <Button className={styles.footerButton} key={item.id} onClick={onMenuItemClick} {...item}>
                                {item.displayName}
                            </Button>
                        ))}
                    </div>
                    <Toggler open={open} toggleOpen={toggleOpen} />
                </div>
            </div>
        </aside>
    );
};
