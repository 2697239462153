import React, { useState } from 'react';
import { EventBus } from '@vtblife/event-bus';
import { AuthInitialize } from '@vtblife/event-bus-events';

export const AuthModalTester = () => {
    const [isInfoShown, setIsInfoShown] = useState(false);
    const onClick = () => {
        const event: AuthInitialize = {
            type: 'auth:initialize',
            data: window.authModalData || {
                flow: 'authorization',
                modalTitle: 'Избранное',
                phone: '9991112233',
                modalSubtitle: 'Чтобы добавить в избранное войдите или зарегистрируйтесь.',
            },
            category: 'simple',
        };
        EventBus.getInstance().publish(event);
    };

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 999,
                padding: '16px',
                right: '150px',
                background: '#fff',
                border: '1px solid #aaa',
            }}
        >
            <div>
                <button onClick={onClick}>open the auth modal</button>
            </div>
            <div style={{ marginTop: '16px' }}>
                <button onClick={() => setIsInfoShown(!isInfoShown)}>{isInfoShown ? 'hide' : 'show more info'} </button>
            </div>
            {isInfoShown && (
                <p>
                    In order to pass custom{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue' }}
                        href="https://gitlab.m2.ru/vtblife/frontend/libraries/event-bus/-/blob/master/packages/event-bus-events/src/auth/initialization.ts#L6"
                    >
                        parameters
                    </a>{' '}
                    to the auth modal use the window object:
                    <pre>
                        window.authModalData ={' '}
                        {JSON.stringify(
                            {
                                flow: 'authorization',
                                modalTitle: 'Избранное',
                                modalSubtitle: 'Чтобы добавить в избранное войдите или зарегистрируйтесь.',
                                phone: '9991112233',
                                isProfessional: false,
                                shouldSubmitLogin: false,
                            },
                            null,
                            2,
                        )}
                    </pre>
                </p>
            )}
        </div>
    );
};
