import React, { lazy, Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { History } from 'history';
import { Spinner } from '@vtblife/uikit/legacy';
import { Shell as ShellProvider } from '@vtblife/layout-components/shell/shell';
import { ToasterContainer } from '@vtblife/layout-components/shell/components/notification-toaster/toaster-adapter/toaster-adapter';
import { LogoVariant } from '@vtblife/layout-components/header/logo/images';

import { isAutotestUserAgent } from '../../utils';
import { UserStore } from '../../stores/user';
import { ConfigStore } from '../../stores/config';
import { BundleTreeStore } from '../../stores/bundle-tree';
import { ModalStore } from '../../stores/modal-store';
import { LayoutStore } from '../../stores/layout';

import { AuthApp } from '../../components/auth-app/auth-app';
import { ChatApp } from '../../components/chat-app/chat-app';
import { IS_WEBVIEW_CLIENT } from '../../constants';

const PhoneModal = lazy(() =>
    import('../../components/phone-modal/phone-modal').then((module) => ({ default: module.PhoneModal })),
);

interface AppProps {
    initialState: InitialState;
    history: History;
    pathname: string;
    logoVariant?: LogoVariant;
    isFullWidth?: boolean;
    isTransparent?: boolean;
}

interface InjectedAppProps {
    userStore: UserStore;
    configStore: ConfigStore;
    bundleTreeStore: BundleTreeStore;
    layoutStore: LayoutStore;
    modalStore: ModalStore;
}

class App extends React.Component<AppProps & InjectedAppProps, { shouldShowChatWidget: boolean }> {
    constructor(props: AppProps & InjectedAppProps) {
        super(props);
        // Для shouldShowChatWidget дефолтным значением должно оставаться false.
        // иначе загрузка скриптов usedesk начнется до того, как мы его переопределим. И виджет соответственно отобразится
        this.state = { shouldShowChatWidget: false };
    }
    componentDidMount() {
        const { pathname, layoutStore } = this.props;
        let shouldShowChatWidget = this.state.shouldShowChatWidget;
        let userAgent = '';

        if (typeof navigator !== 'undefined') {
            userAgent = window.navigator.userAgent;
        }
        const isUseDeskHiddenInRoute = layoutStore.hideUseDeskRoutes.some((regexp) => regexp.test(pathname));
        // TODO: после замены чата у банка убрать полностью кнопку
        shouldShowChatWidget = !IS_WEBVIEW_CLIENT && !isUseDeskHiddenInRoute && !isAutotestUserAgent(userAgent);

        this.setState({
            shouldShowChatWidget,
        });
    }

    render() {
        const {
            pathname,
            configStore,
            history,
            initialState,
            userStore,
            bundleTreeStore,
            children,
            layoutStore,
            modalStore,
        } = this.props;

        const isCookieConsentHiddenInRoute = layoutStore.hideCookieConsentRoutes.some((regexp) =>
            regexp.test(pathname),
        );
        return (
            <>
                <ShellProvider
                    user={userStore.user}
                    shouldDisableRegionModal={layoutStore.shouldDisableRegionModals}
                    shouldAskCookieAgreement={!isCookieConsentHiddenInRoute}
                    shouldShowChatWidget={this.state.shouldShowChatWidget}
                    config={configStore.toJS()}
                    modalStore={modalStore}
                    pathname={pathname}
                    verification={{ isAlreadyPassedM2ProVerification: userStore.isAlreadyPassedM2ProVerification }}
                >
                    <AuthApp
                        history={history}
                        initialState={initialState}
                        userStore={userStore}
                        configStore={configStore}
                        bundleTreeStore={bundleTreeStore}
                    />
                    <ChatApp
                        history={history}
                        initialState={initialState}
                        userStore={userStore}
                        configStore={configStore}
                        bundleTreeStore={bundleTreeStore}
                    />
                    {children}
                    {this.renderPhoneModal()}
                    <ToasterContainer />
                </ShellProvider>
            </>
        );
    }

    private renderPhoneModal() {
        const user = this.props.userStore.user;
        if (
            !this.props.layoutStore.hasSidebar ||
            !user?.companyId ||
            user?.currentRoleType === 'EMPLOYEE' ||
            !this.props.configStore.isFeatureAvailable('lk-phone-modal')
        ) {
            return null;
        }
        return (
            <Suspense fallback={<Spinner />}>
                <PhoneModal
                    otpAppPath={this.props.configStore.getApplicationPath('otp')}
                    modalStore={this.props.modalStore}
                />
            </Suspense>
        );
    }
}

export const Shell = inject(
    'userStore',
    'configStore',
    'bundleTreeStore',
    'layoutStore',
    'modalStore',
)(observer(App)) as unknown as React.Component<AppProps>;
