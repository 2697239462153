import { createBrowserHistory } from 'history';

import svg from '@vtblife/uikit-icons/dist/svg-inline';
import 'normalize.css';
import '@vtblife/uikit/dist/css/grid.css';
import '@vtblife/uikit/dist/css/global.css';
import '@vtblife/uikit-theme';
import './styles.css';

import { patchHistoryGatsbyRedirects } from './browser/patch-history-gatsby-redirects';
import { bootstrapBrowserEnvironment } from './browser/bootstrap-browser';
import { Compositor } from './browser/compositor';
import { createRootStore } from './create-root-store';

document.body.appendChild(svg);

if (module.hot) {
    module.hot.accept();
}

const initialState = window.__INITIAL_STATE__ as InitialState;
const environment = initialState?.configStore?.appEnvironment;

const { history, rootStore } = createRootStore(createBrowserHistory(), initialState);
patchHistoryGatsbyRedirects(history, initialState?.configStore?.gatsbyManifest || []);
bootstrapBrowserEnvironment(environment, initialState, true);

const compositor = new Compositor(
    rootStore,
    history,
    initialState,
    document.cookie,
    document.getElementById('root')!,
    document.getElementById('app-header')!,
    document.getElementById('app-sidebar')!,
    document.getElementById('app-footer')!,
    document.getElementById('app-main')!,
    document.getElementById('app-modals-manager')!,
);

compositor.update();
history.listen(() => {
    compositor.update();
});
