import React, { FC } from 'react';
import { inject, observer } from 'mobx-react';
import { Sidebar as LayoutComponentsSidebar } from '@vtblife/layout-components/sidebar';

import { SidebarStore } from '../../stores/sidebar';
import { UserStore } from '../../stores/user';
import { useHandleMenuItemClick } from '../../hooks/handle-menu-item-click';

type SidebarProps = {
    sidebarStore: SidebarStore;
    userStore: UserStore;
    className?: string;
};

const Component: FC<SidebarProps> = observer(({ sidebarStore, userStore, className }) => {
    const sidebarConfig = sidebarStore.sidebarConfig(userStore.user);
    const handleMenuItemClick = useHandleMenuItemClick();
    return <LayoutComponentsSidebar onMenuItemClick={handleMenuItemClick} className={className} {...sidebarConfig} />;
});

export const Sidebar = inject('sidebarStore', 'userStore')(Component) as React.FC<
    Omit<SidebarProps, 'sidebarStore' | 'userStore'>
>;
