import React from 'react';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';

import { RootStore } from './stores/root';

export const MobxProvider = ({
    rootStore,
    children,
}: {
    rootStore: RootStore;
    children: React.ReactNode;
}): JSX.Element => {
    return (
        <>
            <Provider
                rootStore={rootStore}
                historyStore={rootStore.historyStore}
                configStore={rootStore.configStore}
                userStore={rootStore.userStore}
                headerStore={rootStore.headerStore}
                footerStore={rootStore.footerStore}
                bundleTreeStore={rootStore.bundleTreeStore}
                layoutStore={rootStore.layoutStore}
                sidebarStore={rootStore.sidebarStore}
                modalStore={rootStore.modalStore}
            >
                {/*
                @ts-expect-error */}
                <Router history={rootStore.historyStore.history}>{children}</Router>
            </Provider>
        </>
    );
};
