import loadjs from 'loadjs';

const __LOADED_BUNDLES__ = '__LOADED_BUNDLES__';

interface Container {
    __LOADED_BUNDLES__?: any;
}

const container: (Window | Record<string, any>) & Container = typeof window !== 'undefined' ? window : {};

export class BundleProvider {
    loadedBundles: Record<string, any> = container[__LOADED_BUNDLES__] || (container[__LOADED_BUNDLES__] = {});

    async getBundle({
        scriptUrls,
        styleUrls,
        bundleName,
    }: {
        scriptUrls: string[];
        styleUrls: string[];
        bundleName: string;
    }) {
        if (container[__LOADED_BUNDLES__][bundleName]) {
            return container[__LOADED_BUNDLES__][bundleName];
        }
        const mainIndex = scriptUrls.findIndex((url: string) => url.endsWith('/main.js'));
        const main = scriptUrls[mainIndex];
        const dependencies = [...scriptUrls.slice(0, mainIndex), ...scriptUrls.slice(mainIndex + 1), ...styleUrls];

        await Promise.all(dependencies.map((dependency) => corsImport(dependency)));
        await corsImport(main);

        return container[__LOADED_BUNDLES__][bundleName];
    }
}

export const bundleProvider = new BundleProvider();

export function corsImport(src: string, noCache = false) {
    const bundleId = noCache ? `${src}?t=${Date.now()}` : src;
    loadjs([src], bundleId, {
        before(_path: string, scriptEl: HTMLScriptElement) {
            scriptEl.crossOrigin = 'anonymous';
        },
        numRetries: 6,
    });

    return new Promise((resolve, reject) => {
        const resolvedTyped = resolve as () => void;
        loadjs.ready(bundleId, {
            success: resolvedTyped,
            error: reject,
        });
    });
}
