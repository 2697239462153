import React, { type ComponentProps, Suspense, type FC } from 'react';
import { Redirect, Route, RouteProps, Router, Switch } from 'react-router-dom';
import { RouterStore, SynchronizedHistory } from 'mobx-react-router';
import { inject, observer } from 'mobx-react';
import { Size } from '@vtblife/uikit/legacy';
import queryString from 'query-string';
import { rootRoutes, RootRoutesType } from '@vtblife/root-src-config';

import { ConfigStore } from '../../stores/config';
import { UserStore } from '../../stores/user';
import { BundleTreeStore } from '../../stores/bundle-tree';
import { HeaderStore } from '../../stores/header';
import { ModalStore } from '../../stores/modal-store';
import { M2Spinner } from '../../components/m2-spinner/m2-spinner';
import { MicroApplication } from '../../components/micro-application/micro-application';
import { ProtectedRoute } from '../../components/protected-route/protected-route';
import { hasRoute, isInternalPath, normalizeRetpath } from '../../utils';
import { SSRPage } from '../../ssr/ssr-page';
import { Logout } from '../../pages/logout/logout';
import { InvoicePage } from '../../pages/invoice/invoice';

export interface AppProps {
    initialState: InitialState;
    history: SynchronizedHistory;
    configStore: ConfigStore;
    headerStore: HeaderStore;
    userStore: UserStore;
    bundleTreeStore: BundleTreeStore;
    historyStore: RouterStore;
    pathname: string;
    cookie: string;
    modalStore: ModalStore;
}

const rootRoutesMapper: Record<RootRoutesType, RouteProps> = {
    logout: {
        ...rootRoutes.logout,
        component: Logout,
    },
    autoTest: {
        ...rootRoutes.autoTest,
        render: () => <></>,
    },
    invoice: {
        ...rootRoutes.invoice,
        component: InvoicePage,
    },
};

@inject('configStore', 'userStore', 'bundleTreeStore', 'historyStore', 'headerStore', 'modalStore')
@observer
export class App extends React.Component<AppProps, { shouldRender: boolean }> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            shouldRender: false,
        };
    }

    async componentDidMount(): Promise<void> {
        const { configStore } = this.props;
        if (!configStore.isInitialized) {
            await configStore.fetchConfig();
        }

        this.setState({ shouldRender: true });
    }

    render() {
        const { configStore, history, bundleTreeStore } = this.props;
        if (!configStore.isInitialized) {
            return null;
        }

        const currentPath = this.props.history.location.pathname;

        if (
            hasRoute(currentPath, bundleTreeStore.bundleTreeRoutes) &&
            !hasRoute(currentPath, bundleTreeStore.availableRoutes)
        ) {
            location.href = '/forbidden';
            return <M2Spinner size={Size.Large} />;
        }

        return (
            <Router history={history}>
                <Switch>
                    {Object.entries(rootRoutesMapper).map(([key, rootRouteConfig]) => (
                        <Route key={key} {...rootRouteConfig} />
                    ))}
                    {(configStore.ssrManifest.routes || []).map((path, index) => (
                        <Route key={index} path={path} exact={true} render={() => <SSRPage />} />
                    ))}
                    {this.renderRoutes()}
                </Switch>
            </Router>
        );
    }

    private renderRoutes() {
        if (!this.state.shouldRender) {
            return null;
        }

        const { initialState, history, userStore, bundleTreeStore } = this.props;

        return bundleTreeStore.availableRoutes.map((route) => {
            const microApp = () => {
                if (route.applicationName == 'auth' && userStore.isAuthorized) {
                    const searchParams = queryString.parse(location.search);
                    const retpath = userStore.onAuthFinishedRedirectPath || searchParams.retpath;

                    delete searchParams.retpath;
                    const searchParamsString =
                        Object.keys(searchParams).length === 0 ? '' : `?${queryString.stringify(searchParams)}`;

                    const redirectUrl = `${decodeURIComponent(
                        normalizeRetpath(retpath) || '/personal-area',
                    )}${searchParamsString}`;
                    if (isInternalPath(redirectUrl, bundleTreeStore.bundleTreeRoutes)) {
                        return <Redirect to={redirectUrl} />;
                    } else {
                        window.location.href = redirectUrl;
                        return;
                    }
                }

                return (
                    <Suspense fallback={<M2Spinner size={Size.Large} />}>
                        <MicroApplication route={route} initialState={initialState} history={history} />
                    </Suspense>
                );
            };

            return route.availableForNotAuthorized ? (
                <Route key={route.path} exact={route.exact} path={route.path} render={microApp} />
            ) : (
                <ProtectedRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    render={microApp}
                    isAuthorized={userStore.isAuthorized}
                />
            );
        });
    }
}

export const ContentIsland: FC<
    Omit<
        ComponentProps<typeof App>,
        'configStore' | 'userStore' | 'bundleTreeStore' | 'historyStore' | 'headerStore' | 'modalStore'
    >
> = (props) => {
    // @ts-expect-error
    return <App {...props} />;
};
