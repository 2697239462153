import React, { FC } from 'react';
import Icon from '@vtblife/uikit-icons';
import cn from 'classnames';
import { Tooltip, useTooltip } from '@vtblife/uikit';

import styles from './toggler.module.css';
import { isTouchDevice } from '../../helpers/is-mobile';

type TogglerProps = {
    open: boolean;
    toggleOpen: () => void;
};

export const Toggler: FC<TogglerProps> = ({ open, toggleOpen }) => {
    const { to, registerControl } = useTooltip();
    return (
        <>
            <span
                onClick={toggleOpen}
                ref={registerControl}
                className={cn(styles.toggler, open && styles.open)}
                data-test={open ? 'side-nav-open' : 'side-nav-closed'}
            >
                <Icon name="chevron-double-right" />
            </span>
            {!isTouchDevice() && (
                <Tooltip placement="body" direction="right" to={to}>
                    {open ? 'Свернуть' : 'Развернуть'} боковую панель
                </Tooltip>
            )}
        </>
    );
};
