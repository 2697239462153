import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { History } from 'history';

import { RootStore } from './stores/root';

export function createRootStore(_history: History, initialState: InitialState) {
    const historyStore = new RouterStore();
    const history = syncHistoryWithStore(_history, historyStore);
    const rootStore = new RootStore(historyStore, initialState);

    return {
        rootStore,
        historyStore,
        history,
    };
}
