import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { getById } from '@vtblife/billing-rest-api/axios-gen/api';

import { InvoiceDto } from '@vtblife/billing-rest-api';

import styles from './invoice.module.css';
import { Spinner } from '../../components/spinner/spinner';
import { InvoicePayment } from './invoice-payment';
import { InvoiceCompleted } from './invoice-completed';
import { publishErrorNotification } from '../../utils';

enum InvoiceStatus {
    PENDING = 'PENDING',
    CREATED = 'CREATED', // требуется показать форму оплаты
    ISSUED = 'ISSUED', // требуется показать форму оплаты
    PAID = 'PAID', //оплачен
    SENT_BILLING = 'SENT_BILLING', //оплачен
    COMPLETED = 'COMPLETED', //оплачен
    ERROR = 'ERROR',
}
export const InvoicePage = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus>(InvoiceStatus.PENDING);
    const [invoiceData, setInvoiceData] = useState<InvoiceDto | null>(null);

    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                const data = await getById({
                    urlParams: {
                        id: invoiceId,
                    },
                });
                setInvoiceData(data);
                setInvoiceStatus(data.status as InvoiceStatus);
            } catch (err) {
                setInvoiceStatus(InvoiceStatus.ERROR);
                publishErrorNotification('Что-то пошло не так');
            }
        };
        fetchInvoiceData();
    }, [invoiceId]);

    const renderInvoiceStatus = useCallback(() => {
        switch (invoiceStatus) {
            case InvoiceStatus.PENDING:
                return <Spinner />;
            case InvoiceStatus.COMPLETED:
            case InvoiceStatus.PAID:
            case InvoiceStatus.SENT_BILLING:
                return <InvoiceCompleted />;
            default:
                return <InvoicePayment invoiceData={invoiceData} />;
        }
    }, [invoiceStatus]);

    return <div className={styles.container}>{renderInvoiceStatus()}</div>;
};
