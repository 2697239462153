import type { TrackingData } from '@growthbook/growthbook';

import { HttpTransport, Transport } from './http-transport';

interface GetFeaturesResponse {
    features: Record<string, any>;
    trackingData: TrackingData[];
}

class GrowthBookApiService {
    transport: Transport;

    constructor(name = 'root', transport?: Transport) {
        this.transport =
            transport ||
            new HttpTransport({
                baseURL: `/api/${name}-bff/`,
            });
    }

    async getFeaturesByPath(path: string): Promise<GetFeaturesResponse> {
        return this.transport.get('/api/growth-book-features', { path });
    }
}

export const growthBookApiService = new GrowthBookApiService();
