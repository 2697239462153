import MobxReactRouter from 'mobx-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { matchPath } from 'react-router-dom';

import { RootStore } from '../stores/root';
import { MobxProvider } from '../mobx-provider';
import { ContentIsland } from '../islands/content-island/content-island';
import { ModalsManagerIsland } from '../islands/modals-manager-island/modals-manager-island';
import { HeaderIsland } from '../islands/header-island/header-island';
import { SidebarIsland } from '../islands/sidebar-island/sidebar-island';
import { FooterIsland } from '../islands/footer-island/footer-island';
import layoutStyles from './compositor.module.css';

interface UpdateContext {
    hasHeader: boolean;
    hasFooter: boolean;
    isContentFullHeight: boolean;
    hasSidebar: boolean;
    isPersonalArea: boolean;
    layout: 'anonymousArea' | 'personalArea' | 'public';
}

export class Compositor {
    isHeaderInitialized: boolean;
    isSidebarInitialized: boolean;
    isContentInitialized: boolean;
    isFooterInitialized: boolean;
    isModalManagerInitialized: boolean;

    constructor(
        public rootStore: RootStore,
        public history: MobxReactRouter.SynchronizedHistory,
        public initialState: InitialState,
        public cookie: string,
        public rootEl: HTMLElement,
        public appHeaderEl: HTMLElement,
        public appSidebarEl: HTMLElement,
        public appFooterEl: HTMLElement,
        public appContentEl: HTMLElement,
        public appModals: HTMLElement,
    ) {}

    update() {
        const isAnonymousUser = this.rootStore.userStore.isAnonymousUser;
        const isPersonalArea = Boolean(this.rootStore.layoutStore.hasSidebar);

        const layout = isAnonymousUser && isPersonalArea ? 'anonymousArea' : isPersonalArea ? 'personalArea' : 'public';

        const hasHeader = this.rootStore.layoutStore.hasHeader || layout === 'anonymousArea';
        const hasFooter = this.rootStore.layoutStore.hasFooter;
        const hasSidebar = Boolean(this.rootStore.layoutStore.hasSidebar) && layout !== 'anonymousArea';

        const isContentFullHeight = !hasFooter;

        const ctx: UpdateContext = {
            hasHeader,
            hasFooter,
            isContentFullHeight,
            hasSidebar,
            isPersonalArea,
            layout,
        };

        this.updateHeader(ctx);
        this.updateSidebar(ctx);
        this.updateContent(ctx);
        this.updateFooter(ctx);
        this.updateModalsManager(ctx);
    }

    updateHeader(ctx: UpdateContext) {
        if (ctx.hasHeader) {
            if (!this.isHeaderInitialized) {
                this.isHeaderInitialized = true;

                const app = (
                    <MobxProvider rootStore={this.rootStore}>
                        <HeaderIsland />
                    </MobxProvider>
                );

                if (this.appHeaderEl.childNodes.length === 0) {
                    renderReact(app, this.appHeaderEl);
                } else {
                    hydrateReact(app, this.appHeaderEl);
                }
            }
        }
    }

    updateSidebar(ctx: UpdateContext) {
        if (ctx.hasSidebar) {
            if (!this.isSidebarInitialized) {
                this.isSidebarInitialized = true;

                const app = (
                    <MobxProvider rootStore={this.rootStore}>
                        <SidebarIsland />
                    </MobxProvider>
                );

                // never SSR
                renderReact(app, this.appSidebarEl);
            }
        }
    }

    updateContent(ctx: UpdateContext) {
        if (!this.isContentInitialized) {
            this.isContentInitialized = true;

            (async () => {
                const pathname = this.rootStore.historyStore.location.pathname;
                const ssrRoutes = this.rootStore.configStore.ssrManifest.routes || [];
                const isSsr = ssrRoutes.find((route) => matchPath(pathname, route));

                const env = this.initialState.configStore?.appEnvironment;
                if (env !== 'production') {
                    const { initCopperfield } = await import('@vtblife/copperfield');
                    await initCopperfield();
                }

                const app = (
                    <MobxProvider rootStore={this.rootStore}>
                        <ContentIsland
                            history={this.history}
                            initialState={this.initialState}
                            cookie={this.cookie}
                            pathname={pathname}
                        />
                    </MobxProvider>
                );

                if (isSsr && this.appContentEl.childNodes.length > 0) {
                    hydrateReact(app, this.appContentEl);
                } else {
                    renderReact(app, this.appContentEl);
                }
            })();
        }

        if (!ctx.isPersonalArea && ctx.isContentFullHeight) {
            this.appContentEl.style.display = 'flex';
            this.appContentEl.style.flexDirection = 'column';
        } else {
            this.appContentEl.style.display = 'block';
            this.appContentEl.style.flexDirection = '';
        }

        this.rootEl.className =
            ctx.layout === 'anonymousArea'
                ? layoutStyles.anonymousArea
                : ctx.layout === 'personalArea'
                ? layoutStyles.personalArea
                : layoutStyles.publicArea;
    }

    updateFooter(ctx: UpdateContext) {
        if (ctx.hasFooter) {
            if (!this.isFooterInitialized) {
                this.isFooterInitialized = true;

                const app = (
                    <MobxProvider rootStore={this.rootStore}>
                        <FooterIsland />
                    </MobxProvider>
                );

                if (this.appFooterEl.childNodes.length === 0) {
                    renderReact(app, this.appFooterEl);
                } else {
                    hydrateReact(app, this.appFooterEl);
                }
            }

            this.appFooterEl.style.display = 'block';
        } else {
            this.appFooterEl.style.display = 'none';
        }
    }

    updateModalsManager(_ctx: UpdateContext) {
        if (!this.isModalManagerInitialized) {
            this.isModalManagerInitialized = true;

            // never SSR
            renderReact(
                <MobxProvider rootStore={this.rootStore}>
                    <ModalsManagerIsland
                        history={this.history}
                        initialState={this.initialState}
                        cookie={this.cookie}
                        pathname={this.rootStore.historyStore.location.pathname}
                    />
                </MobxProvider>,
                this.appModals,
            );
        }
    }
}

function renderReact(reactNode: React.ReactElement, rootEl: HTMLElement) {
    if (window.ENABLE_REACT_18) {
        // @ts-expect-error typings are still from react-16
        ReactDOM.createRoot(rootEl).render(reactNode);
    } else {
        ReactDOM.render(reactNode, rootEl);
    }
}

function hydrateReact(reactNode: React.ReactElement, rootEl: HTMLElement) {
    if (window.ENABLE_REACT_18) {
        // @ts-expect-error typings are still from react-16
        ReactDOM.hydrateRoot(rootEl, reactNode);
    } else {
        ReactDOM.hydrate(reactNode, rootEl);
    }
}
