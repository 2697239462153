import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { hasStorage } from '../utils/has-storage';

const STATE_PREFIX = 'sidebar_dropdown_menu_state_';

export const useSidebarMenuState = (id: string, initialState?: boolean): [boolean, () => void] => {
    const { pathname } = useLocation();
    const hasSessionStorage = hasStorage('sessionStorage');
    const key = `${STATE_PREFIX + id}`;
    const savedState = initialState || Boolean(hasSessionStorage && sessionStorage.getItem(key));
    const [state, setState] = useState(savedState);
    const saveState = (state: boolean) => {
        hasSessionStorage && sessionStorage.setItem(key, state ? '1' : '');
    };

    const toggleState = () => {
        setState((state) => {
            if (id) {
                window.dataLayer.push({
                    event: 'lk_action',
                    section: 'menu',
                    page_type: 'lk',
                    type: id,
                });
            }
            saveState(!state);
            return !state;
        });
    };

    useEffect(() => {
        if (initialState && !state) {
            toggleState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return [state, toggleState];
};
