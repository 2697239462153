import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
    isAuthorized: boolean;
}

export function ProtectedRoute({ render = () => <></>, path, exact, isAuthorized }: Props) {
    function withAuthorization(props: RouteComponentProps<any>) {
        if (!isAuthorized) {
            const { pathname, search } = location;
            const retpath = encodeURIComponent(pathname);
            const redirectUrl = `/login?retpath=${retpath}${search.replace('?', '&')}`;
            return <Redirect to={redirectUrl} />;
        }

        return render(props);
    }

    return <Route exact={exact} path={path} render={withAuthorization} />;
}
