import React from 'react';
import { Size, Spinner as UIKitSpinner } from '@vtblife/uikit/legacy';

import styles from './spinner.module.css';

export const Spinner = (): JSX.Element => (
    <div className={styles.spinnerWrapper}>
        <UIKitSpinner size={Size.Large} />
    </div>
);
