import React from 'react';
import { RRLink, Typography } from '@vtblife/uikit';
import Icon from '@vtblife/uikit-icons';

import styles from './wallet-link.module.css';
import { sendAnalyticsEvent } from '../../analytics/send-analytics-event';
import { useDisplayWallet } from '../../hooks/use-display-wallet';

export const WalletLink: React.FC = () => {
    const { walletLink, walletText } = useDisplayWallet();
    return (
        <RRLink
            href={walletLink}
            text={false}
            onClick={() => sendAnalyticsEvent({ event: 'lk_action', page_type: 'lk', section: 'menu', type: 'wallet' })}
        >
            <span className={styles.link}>
                <Icon name="m2-wallet" size="s" />
                <Typography tag="span" variant="small-alone" color="primary" className={styles.text}>
                    {walletText}
                </Typography>
                <Icon name="chevron-right" size="xs" color="rgba(116, 109, 147, 0.5)" />
            </span>
        </RRLink>
    );
};
