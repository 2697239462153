import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { ShellAuthorizeContextValue, ShellUserRegionContextValue } from '@vtblife/layout-components/shell/contexts';
import { useUserRegion } from '@vtblife/layout-components/shell/hooks/use-user-region';
import { RegionModals } from '@vtblife/layout-components/shell/components/region-modals/region-modals';

import { Header } from '../../components/header/header';
import { RootStore } from '../../stores/root';
import { Sidebar } from '../../components/side-menu/sidebar';
import { IS_WEBVIEW_CLIENT } from '../../constants';
import styles from './sidebar-island.module.css';

// @TODO: inject
export const SidebarIslandInner = ({ rootStore }: { rootStore: RootStore }) => {
    const pathname = rootStore.historyStore.location.pathname;
    const user = rootStore.userStore.user;
    const isAlreadyPassedM2ProVerification = rootStore.userStore.isAlreadyPassedM2ProVerification;
    const isAuthorized = Boolean(user && user.isAuthenticated);
    const shouldDisableRegionModal = rootStore.layoutStore.shouldDisableRegionModals;
    const userRegion = useUserRegion();

    useEffect(() => {
        const unsubscribe = userRegion.callSubscribe();
        return () => unsubscribe();
    }, [userRegion.callSubscribe]);

    return (
        <ShellAuthorizeContextValue.Provider
            value={{
                authorize: {
                    user,
                    isAuthorized,
                    verification: { isAlreadyPassedM2ProVerification },
                },
            }}
        >
            <ShellUserRegionContextValue.Provider
                value={{
                    userRegion,
                }}
            >
                <Sidebar className={styles.desktopSidebar} />
                {!IS_WEBVIEW_CLIENT && <Header className={styles.mobileSidebarHeader} pathname={pathname} />}
                {!shouldDisableRegionModal && <RegionModals />}
            </ShellUserRegionContextValue.Provider>
        </ShellAuthorizeContextValue.Provider>
    );
};

export const SidebarIsland = inject('rootStore')(observer(SidebarIslandInner)) as React.FC;
