import { RouteProps } from 'react-router-dom';

export type RootRoutesType = 'logout' | 'autoTest' | 'invoice';
export const rootRoutes: Record<RootRoutesType, RouteProps> = {
    logout: {
        path: '/logout',
        exact: true,
    },
    autoTest: {
        path: '/.empty',
        exact: true,
    },
    invoice: {
        path: '/invoice/:invoiceId',
        exact: false,
    },
};
