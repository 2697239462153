import { NotificationPayload } from '@vtblife/event-bus-events';
import { reportErrorToSentry } from '@vtblife/layout-components';
import { showToasterNotification } from '@vtblife/layout-components/shell/components/notification-toaster/toaster-adapter/toaster-adapter';

export const publishErrorNotification = (
    message: NotificationPayload['message'],
    options?: NotificationPayload['options'],
) => {
    showToasterNotification({ type: 'error', message, options });
    if (options?.error) {
        reportErrorToSentry({ error: options.error });
    }
};

export const publishSuccessNotification = (
    message: NotificationPayload['message'],
    options?: NotificationPayload['options'],
) => {
    showToasterNotification({ type: 'success', message, options });
};
